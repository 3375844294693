body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}


:root {
  /* colors */
  --color-background: #FFF8F1;
  --color-primary: #A16AE8;
  --color-secondary: #603F8B;
  --color-tertiary: #424B8C;
  --color-font-primary: #000000;
  --color-font-secondary: #FFFFFF;
  --color-font-tertiary: #603F8B;
  --color-text-highlight: #85E6C5;
  /* font sizes desktop */
  --font-size-title: 3rem;
  --font-size-header: 2.8rem;
  --font-size-paragraph-big: 2rem;
  --font-size-paragraph-small: 1.8rem;
  --font-size-text-big: 1.4rem;
  --font-size-text-small: 1rem;
  /* font sizes mobile */
  --font-size-title-mobile: 1.7rem;
  --font-size-header-mobile: 1.7rem;
  --font-size-paragraph-big-mobile: 1.2rem;
  --font-size-paragraph-small-mobile: 1.5rem;
  --font-size-text-big-mobile: 1.1rem;
  --font-size-text-small-mobile: 0.8rem;
  /* font sizes dynamic */
  --font-size-text-big-dynamic: 2.5vh;
  /* font sizes other */
  --font-size-small: 0.8rem;
  --font-text-indent-small: 1rem;
  --font-weight-md: 700;
  /* sizes */
  --header-height: 6rem;
}