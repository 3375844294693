:root {
	--accent-color: #A16AE8;
}

.wrapper {
	/* display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: 1000px; */
}

.card {
	width: 420px;
	height: 380px;
	margin: 1em;
	perspective: 1500px;
}

.button {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	font-size: var(--font-size-text-small);
	cursor: pointer;
	display: block;
	padding: 0 1.5em;
	height: 3em;
	line-height: 2.9em;
	min-width: 3em;
	background-color: transparent;
	border: solid 2px #fff;
	color: #fff;
	border-radius: 4px;
	text-align: center;
	backface-visibility: hidden;
	transition: 0.3s ease-in-out;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

	position: relative;
	top: 230px;
}
.button:hover {
	background-color: var(--color-background);
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
	text-shadow: none;
	color: var(--color-secondary);
}

.return {
	line-height: 3em;
	color: var(--color-secondary);
	border-color: $accent-color;
	text-shadow: none;
}
.return:hover {
	background-color: var(--color-secondary);
	color: var(--color-background);
	box-shadow: none;
}

.content {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
}

.more {
	display: none!important;
}

.more:checked ~ .content {
	transform: rotateY(180deg);
}

.front,
.back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	border-radius: 6px;
}

.inner {
	height: 100%;
	display: grid;
	padding: 1.5em;
	transform: translateZ(80px) scale(0.94);
}
.description {
	text-align: center;
	grid-row: 1;
	grid-column: 1/-1;
	font-size: var(--font-size-text-small);
	border-radius: 5px;
	font-weight: 600;
	line-height: 1.4em;
	overflow: auto;
	color: var(--color-secondary);
	height: 280px;
}

.front {
	background-color: #fff;
	background-size: cover;
	background-position: center center;
}
.front:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 6px;
	backface-visibility: hidden;
	background: linear-gradient(
		45deg,
		var(--color-secondary),
		var(--color-primary)
	);
}
.front .inner {
	grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
	justify-items: center;
}
.front h2 {
	grid-row: 2;
	margin-bottom: 0.3em;
	text-transform: uppercase;
	color: var(--color-background);
	font-size: var(--font-size-text-big);
	font-weight: 500;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	position: relative;
	top: -100px;
	text-align: center;
}

.back {
	transform: rotateY(180deg);
	background-color: var(--color-background);
	border: 2px solid rgb(240, 240, 240);
}
.back .inner {
	grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
	grid-template-columns: repeat(4, auto);
	grid-column-gap: 0.8em;
	justify-items: center;
}

.back .button {
	font-size: var(--font-size-text-small);
	grid-column: 1/-1;
	justify-self: center;
	top: 10px;
	border-color: var(--color-secondary);
	color: var(--color-secondary);
}


::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background: lighten(var(--accent-color), 20%);
}
::-webkit-scrollbar-thumb:hover {
	background: var(--accent-color);
}


/* SMALL SCREEN */
.small-card {
	
	width: 90vw;
	height: 200px;
	margin: 1em;
	perspective: 1500px;

	transition: height 0.8s cubic-bezier(0.75, 0, 0.85, 1);

	.button {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
		cursor: pointer;
		display: block;
		padding: 0 1.5em;
		height: 3em;
		line-height: 2.9em;
		min-width: 3em;
		background-color: transparent;
		border: solid 2px #fff;
		color: #fff;
		border-radius: 4px;
		text-align: center;
		backface-visibility: hidden;
		transition: 0.3s ease-in-out;
		text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
	
		position: relative;
		top: 100px;
	
		&:hover {
			background-color: var(--color-background);
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
			text-shadow: none;
			color: var(--color-secondary);
		}
	
		&.return {
			line-height: 3em;
			color: var(--color-secondary);
			border-color: var(--accent-color);
			text-shadow: none;
			&:hover {
				background-color: var(--color-secondary);
				color: var(--color-background);
				box-shadow: none;
			}
		}
	}

	.content {
		position: relative;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
	}

	.more {
		display: none!important;
	
		&:checked ~ .content {
			transform: rotateY(180deg);
		}
	}

	.front,
	.back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		border-radius: 6px;

		.inner {
			height: 100%;
			display: grid;
			padding: 1.5em;
			transform: translateZ(80px) scale(0.94);
		}

		.description {
			text-align: center;
			grid-row: 1;
			grid-column: 1/-1;
			font-size: var(--font-size-text-small);
			border-radius: 5px;
			font-weight: 600;
			line-height: 1.4em;
			overflow: auto;
			color: var(--color-secondary);
			height: 340px;
		}
	}

	.front {
		background-color: #fff;
		background-size: cover;
		background-position: center center;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 6px;
			backface-visibility: hidden;
			background: linear-gradient(
				45deg,
				var(--color-secondary),
				var(--color-primary)
			);
		}
		.inner {
			grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
			justify-items: center;
		}
	
		h2 {
			grid-row: 2;
			margin-bottom: 0.3em;
			text-transform: uppercase;
			color: var(--color-background);
			font-weight: 500;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
			position: relative;
			top: -70px;
			text-align: center;
		}

	}

	.back {
		transform: rotateY(180deg);
		background-color: var(--color-background);
		border: 2px solid rgb(240, 240, 240);
		.inner {
			padding: 0 5px 0 5px;
			grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
			grid-template-columns: repeat(4, auto);
			grid-column-gap: 0.8em;
			justify-items: center;
		}

		.description {
			height: 365px;
		}
	
		.button {
			grid-column: 1/-1;
			justify-self: center;
			top: 10px;
			border-color: var(--color-secondary);
			color: var(--color-secondary);
		}
	}
}

.big-card {
	width: 420px;
	height: 380px;
	margin: 1em;
	perspective: 1500px;

	.button {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
		font-size: var(--font-size-text-small);
		cursor: pointer;
		display: block;
		padding: 0 1.5em;
		height: 3em;
		line-height: 2.9em;
		min-width: 3em;
		background-color: transparent;
		border: solid 2px #fff;
		color: #fff;
		border-radius: 4px;
		text-align: center;
		backface-visibility: hidden;
		transition: 0.3s ease-in-out;
		text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
	
		position: relative;
		top: 230px;
	
		&:hover {
			background-color: var(--color-background);
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
			text-shadow: none;
			color: var(--color-secondary);
		}
	
		&.return {
			line-height: 3em;
			color: var(--color-secondary);
			border-color: var(--accent-color);
			text-shadow: none;
			&:hover {
				background-color: var(--color-secondary);
				color: var(--color-background);
				box-shadow: none;
			}
		}
	}

	.content {
		position: relative;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
	}

	.more {
		display: none!important;
	
		&:checked ~ .content {
			transform: rotateY(180deg);
		}
	}

	.front,
	.back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		border-radius: 6px;

		.inner {
			height: 100%;
			display: grid;
			padding: 1.5em;
			transform: translateZ(80px) scale(0.94);
		}

		.description {
			text-align: center;
			grid-row: 1;
			grid-column: 1/-1;
			font-size: var(--font-size-text-small);
			border-radius: 5px;
			font-weight: 600;
			line-height: 1.4em;
			overflow: auto;
			color: var(--color-secondary);
			height: 280px;
		}
	}

	.front {
		background-color: #fff;
		background-size: cover;
		background-position: center center;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 6px;
			backface-visibility: hidden;
			background: linear-gradient(
				45deg,
				var(--color-secondary),
				var(--color-primary)
			);
		}
		.inner {
			grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
			justify-items: center;
		}
	
		h2 {
			grid-row: 2;
			margin-bottom: 0.3em;
			text-transform: uppercase;
			color: var(--color-background);
			font-size: var(--font-size-text-big);
			font-weight: 500;
			text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
			position: relative;
			top: -100px;
			text-align: center;
		}

	}

	.back {
		transform: rotateY(180deg);
		background-color: var(--color-background);
		border: 2px solid rgb(240, 240, 240);
		.inner {
			grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
			grid-template-columns: repeat(4, auto);
			grid-column-gap: 0.8em;
			justify-items: center;
		}
	
		.button {
			font-size: var(--font-size-text-small);
			grid-column: 1/-1;
			justify-self: center;
			top: 10px;
			border-color: var(--color-secondary);
			color: var(--color-secondary);
		}
	}
}


/* Mobile */

.small-card {
	width: 90vw;
	height: 200px;
	margin: 1em;
	perspective: 1500px;

	transition: height 0.8s cubic-bezier(0.75, 0, 0.85, 1);
}