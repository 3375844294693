.container {
    top: 0;
    display: flex;
    justify-content: center;
    height: var(--header-height);
}

.containerContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 75%;
}

.containerLogo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
}

.item {
    font-size: var(--font-size-text-big);
    margin-right: 3rem;
    .signUp {
        padding: .3rem 1rem;
        background-color: var(--color-secondary);
        color: var(--color-font-secondary);
        border-radius: 25px;
    }
}

.item:hover {
    cursor: pointer;
}

.item:hover .underline {
    position: absolute;
    width: 125px;
    height: 15px;
    display: block;
}
.item:hover .underlineSm {
    position: absolute;
    width: 85px;
    height: 15px;
    display: block;
}

.logo {
    height: 110%;
}

.underline, .underlineSm {
    display: none;
}

/* MOBILE */
.containerMobile {
  display: flex;
  width: 100%;
  height: var(--header-height);
  justify-content: space-between;
  position: fixed;
  z-index: 99999;
  background: var(--color-background);
}

.headerMobileSpacing {
    padding-bottom: var(--header-height);
}

.logoMobile {
    height: 95px;
}

.hamburgerMenu {
  color: var(--color-secondary)
}

.hamburgerMenu:hover {
  cursor: pointer;
}

.containerLogoMobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hamburgerMenuContainer {
  display: flex;
  align-items: center;
  width: 3rem;
}
