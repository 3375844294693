.carouselContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.carouselArrowsContainer {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.carouselArrowsContainerMobile {
    display: flex;
    width: 100%;
    justify-content: center;
}

.arrowRight{
    position: relative;
    top: 2rem;
    margin-left: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.arrowLeft{
    position: relative;
    top: 2rem;
    margin-right: 5px;
    rotate: 180deg;
    width: 50px;
    height: 50px;
    cursor: pointer;
}