.registrationPage {
    background-image: url("../../assets/background_ce_invatam.png");
    min-height: calc(100vh - var(--header-height));
}

.registerForm {
    margin: auto; 
    max-width: 1600px;
    padding: 0px 16px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.registerForm h1 {
    padding-top: 0;
    margin-top: 64px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.registerForm p {
    width: 100%;
    color: white;
    font-weight: normal;
    text-indent: 0;
}

.registerForm .submitButton {
    width: 100%;
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputField {
    position: relative;
    margin: 16px 0;
}

.inputField h2 {
    color: white;
    font-weight: normal;
}

.inputField input,
.inputField textArea {
    color: white;
    width: calc(100vw - 70px);
    max-width: 1600px;
    border-radius: 32px;
    font-size: var(--font-size-paragraph);
    padding: 0 15px;
    border: 4px solid white;
    background: transparent;
    outline: none;
}
.inputField textarea {
    max-width: 1560px;
    padding: 12px 15px;
}
.inputField input {
    height: 60px;
}
.inputFieldSmall input {
    max-width: 600px;
    height: 60px;
}

input ~ label {
    color: white;
}
.inputField label {
    font-weight: bold;
    position: absolute;
    top: 16px;
    left: 32px;
    font-size: 24px;
    pointer-events: none;
    transition: 0.3s;
    color: white;
}
input:focus,
textarea:focus {
    border: 4px solid var(--color-primary);
}

input:focus ~ label,
input:valid ~ label,
textarea:focus ~ label,
textarea:valid ~ label {
    top: -10px;
    left: 32px;
    font-size: 16px;
    padding: 0 2px;
    background-color: var(--color-secondary);
}
input[type="checkbox"]:focus ~ label,
input[type="checkbox"]:valid ~ label,
textarea[type="checkbox"]:focus ~ label,
textarea[type="checkbox"]:valid ~ label {
    padding: 0;
}

.errorMessage {
    color: var(--color-text-highlight) !important;
}

/* https://moderncss.dev/pure-css-custom-checkbox-style/ */

input[type="checkbox"] {
    cursor: pointer;

    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
  
    font: inherit;
    color: white;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid white;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  
    display: inline-grid;
    margin: 0 8px 0 4px;
    place-content: center;
}
  
input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: white;
}
  
input[type="checkbox"]:checked::before {
    transform: scale(1);
}
  
input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);
  
    color: var(--form-control-disabled);
    cursor: not-allowed;
}