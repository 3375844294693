.registryButton {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
}

.imageFrame {
    width: 100%;
    margin: auto; 
    overflow: hidden; 
    max-width: 600px;
}
.imageFrame img {
    object-fit: cover;
    width: 100%;
    border-radius: 10px; 
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

/* https://codepen.io/condensed/pen/NWErOEz */
.masonryWithColumns {
    padding: 3rem 0.5rem;
    width: 80%;
    max-width: 1400px;
    margin: auto;
    columns: 2 400px;
    column-gap: 10rem;
}
.offerSection {
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
    margin-bottom: 1rem;
    font-weight: 700;
}
.offerSection h2, .offerSection h1, .offerSection h3 {
    text-align: center;
    font-size: var(--font-size-paragraph-big);
    color: var(--color-secondary);
}
.offerSection ul {
    margin: 0;
    padding: 0;
}
.offerSection ul li {
    color: var(--color-font-primary);
    margin-top: 0;
    margin-left: 16px;
    font-size: var(--font-size-paragraph);
}

.offerSectionMobile {
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
    margin-bottom: 1rem;
    font-weight: 700;
}
.offerSectionMobile h2, .offerSectionMobile h1, .offerSectionMobile h3 {
    text-align: center;
    font-size: var(--font-size-header-mobile);
    color: var(--color-secondary);
}
.offerSectionMobile ul {
    margin: 0;
    padding: 0;
}
.offerSectionMobile ul li {
    color: var(--color-font-primary);
    margin-top: 0;
    margin-left: 16px;
}

.offerSectionMobile p {
    font-size: var(--font-size-text-big-mobile);
}