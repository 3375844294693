.backgroundFilter {
    background-color: rgb(45, 0, 87);
    content: "";
    width: 100vw;
    height: calc(100vh - var(--header-height));
    z-index: -1;
    position: absolute;
    top: 0;
    filter: opacity(0.5);
    display: block;
}

.mainSection {
    height: calc(100vh - var(--header-height));
    position: relative;
}

.arrowDoodle {
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    bottom: 10vh;
    width: 5rem;
    height: 5rem;
    animation: bouncing 5s ease-in-out infinite;
    cursor: pointer;
}

.bellowArrowDoodleText {
    /* text */
    color: var(--color-background);
    font-size: var(1vw);
    /* positioning */
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    bottom: 7vh;
}

.mainHeader {
    /* text */
    font-weight: bold;
    font-size: var(--font-size-title);
    color: var(--color-font-secondary);
    /* positioning */
    width: 45rem;
    top: 15%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.mainHeaderMobile {
    /* text */
    font-weight: bold;
    font-size: var(--font-size-title-mobile);
    color: var(--color-font-secondary);
    /* positioning */
    width: 100%;
    top: 12%;
    position: absolute;
    text-align: center;
    padding: 40px 0;
    background-color: var(--color-secondary);
}

.mainText {
    /* text */
    font-size: var(--font-size-paragraph-big);
    color: var(--color-font-secondary);
    font-weight: bold;
    /* positioning */
    width: 45rem;
    top: 40%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.mainTextMobile {
    /* text */
    font-size: var(--font-size-paragraph-big-mobile);
    color: var(--color-secondary);
    font-weight: bold;
    text-align: center;
    /* positioning */
    padding: 0 40px;
    top: 40%;
    position: absolute;
    left: 0;
    right: 0;
}

.bellowArrowDoodleTextMobile {
    text-align: center;
    width: 7rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    bottom: 7vh;
    color: var(--color-secondary);
}

.container {
    display: flex;
    height: calc(100vh - var(--header-height));
    top: 10rem;
}
.part {
    display: flex;
    justify-content: center;
    width: 50vw;
    bottom: 0;
    position: relative;
}
    
.mainDoodle {
    position: absolute;
    left: 0;
    transform: scaleX(-1);
    width: 15rem;
    height: 15rem;
}

.sectionContainer {
    position: relative;
    width: 50vw;
}

.rightSideArrowDoodle {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    width: 5rem;
    height: 5rem;
    animation: bouncing 5s ease-in-out infinite;
    cursor: pointer;
}

.hatDoodle {
    position: absolute;
    bottom: 1vh;
    right: 1vw;
    margin-top: 11rem;
    width: 5rem;
    height: 5rem;
}            

/* ABOUT US SECTION */

.aboutUsBookDoodle {
    width: 5rem;
    height: 5rem;
    /* wierd centering thing bellow >:[ */
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.aboutUsBulletPointList li {
    margin-top: 5vh;
    margin-left: 3vw;
    font-size: calc(var(--font-size-text-big-dynamic) * 1.2);
    color: var(--color-background);
}

.aboutUsBulletPointList strong {
    color: var(--color-text-highlight);
}
.aboutUsBulletPointMobileList strong {
    color: var(--color-text-highlight);
}

.aboutUsParaghraph {
    text-indent: var(--font-text-indent-small);
    color: var(--color-font-tertiary);
    font-weight: bold;
    font-size: var(--font-size-text-big-dynamic);
}

.aboutUsHeader {
    font-size: var(--font-size-header);
    margin-top: 13vh;
    text-align: center;
}

.aboutUsHeaderMobile {
    font-size: var(--font-size-header-mobile);
    margin-block-start: 15vh;
    text-align: center;
    font-weight: bold;
}

.aboutUsHighlightDoodleMobile {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 10rem;
    top: 5vh;
}

.aboutUsParaghraphMobile {
    text-indent: var(--font-text-indent-small);
    color: var(--color-font-tertiary);
    font-weight: bold;
    font-size: var(--font-size-text-big-mobile);
}

.aboutUsBulletPointMobileList li {
    margin-top: 3vh;
    margin-left: 3vw;
    font-size: var(--font-size-text-big-mobile);
    color: var(--color-background);
}

ul {
    margin-block-start: 15vh;
    font-weight: bold;
}

.aboutUsBulletPointMobileList {
    margin-top: 0;
    font-weight: bold;
    padding-top: 30px;
}

.paragraphGroup {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}

.doodlesGroup {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 1vh;
}

@keyframes bouncing {
    0%, 80%, 90%, 100% {
        transform: translateY(0);
    }
    85% {
        transform: translateY(-10px);
    }
    95% {
        transform: translateY(-10px);
    }
  }

.townDoodle {
    position: absolute;
    width: 50vw;
    height: 85vh;
    bottom: 0;

    z-index: -1;
}

.mainClassRoomBg {
    position: absolute;
    width: 100vw;
    height: calc(100vh - var(--header-height));
    filter: blur(3px);
    z-index: -2;
}

.classRoomBg {
    position: absolute;
    width: 50vw;
    height: 100%;
    bottom: 0;

    z-index: -1;

    object-fit:cover;
}

.classRoomBgMobile {
    position: absolute;
    width: 100vw;
    height: 85vh;
    bottom: 0;

    z-index: -1;

    object-fit:cover;
}

h1 {
    color: var(--color-font-tertiary);
    font-size: var(--font-size-title);
}

.textHighlight {
    color: var(--color-text-highlight);
}

/* OFFER SECTION */

.offerSection {
    width: 100vw;
    background-color: var(--color-secondary);
    position: relative;
    min-height: calc(100vh - var(--header-height));
    color: white;
}

.offerSection p {
    color: white;
}

.offerDoodles {
    display: inline;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.globeDoodle {
    position: absolute;
    width: 35vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: opacity(0.2) invert(16%) sepia(21%) saturate(755%) hue-rotate(150deg) brightness(98%) contrast(97%);       
}

.linesDoodle {
    top: 2vw;
    left: 2vw;
    position: absolute;
    width: 6vw;
}

.smileyDoodle {
    bottom: 2vw;
    right: 2vw;
    position: absolute;
    width: 6vw;
}

.exclamationDoodle {
    top: 2vw;
    right: 2vw;
    position: absolute;
    width: 6vw;
}

.starsDoodle {
    bottom: 2vw;
    left: 2vw;
    position: absolute;
    width: 6vw;
}

.textUnderline {
    width: 40vw;
    z-index: 2;
    width: 10%;
    margin-bottom: 5vh;
}

.textUnderlineMobile {
    position: relative;
    top: -10px;
    width: 30vw;
    height: 3vw;
    z-index: 2;
    margin-bottom: 5vh;
}

.offerText {
    text-indent: 0;
    color: white;
    font-size: var(--font-size-text-small);
    font-weight: 400;
}

.offerTextMobile {
    text-indent: 0;
    color: white;
    font-size: var(--font-size-text-small-mobile);
    font-weight: bold;
}
    
.offerCardsContainer {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    min-height: 40vh;
    justify-content: center;
}

.offerCard {
    display: flex;
    height: 60vw;
    max-height: 340px;
    width: 30vw;
    max-width: 180px;
    content: "";
    z-index: 2;
    border: 5px solid white;
    border-radius: 3px;
    position: relative;

    flex-direction: column;
    justify-content: space-between; 
    text-align: center;

    padding: 12px 32px 32px;
}

.offerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--color-secondary);
}

.offerHeader {
    color: var(--color-background);
    font-weight: bold;
    font-size: var(--font-size-title);
    margin: 32px 0 0;
}

.offerHeaderMobile{
    color: var(--color-background);
    font-weight: bold;
    font-size: var(--font-size-title-mobile);
}

.offerRegisterButton {
    z-index: 2;
    padding-bottom: 24px;
}

.offerCard::before {
    border-radius: 3px;
    border: 5px solid white;
    border-left: none;
    border-bottom: none;
    content: "";
    position: absolute;
    height: 45%;
    width: 45%;
    right: 7px;
    top: 7px;
    display: inline-block;
}
.offerCard::after {
    border-radius: 3px;
    border: 5px solid white;
    border-right: none;
    border-top: none;
    content: "";
    position: absolute;
    bottom: 7px;
    left: 7px;
    height: 45%;
    width: 45%;
    display: inline-block;
}

.offerButton {
    bottom: 0;
    padding: 8px 0px;
    margin: 0;
    position: relative;
    font-size: var(--font-size-text-small);
    z-index: 10;
}
.offerButton::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 18px; 
    padding: 2px; 
    background: linear-gradient(45deg,rgb(174, 0, 255),rgb(0, 255, 255)); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
}

.servicesOfferContent li {
    font-weight: normal;    
}
.servicesOfferContent ul {
    margin: 0;
}

/* TESTIMONIALS */

.testimonialsSection {
    width: 100vw;
    position: relative;
}

.testimonialsTitle {
    display: flex;
    width: 70vw;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    .titleHeader {
        font-size: var(--font-size-title);
        color: var(--color-background);
        text-align: center;
        font-weight: bold;
        width: 80vw;
    }

    .highlight {
        height: 4vh;
        width: 45vw;
        max-width: 45rem;
    }
}

.testimonialsDoodles {
    height: 12rem;

    .bubbleLeft {
        rotate: 1deg;
        left: 2vw;
        bottom: 3rem;
        position: absolute;
        height: 15vw;
    }

    .bubbleRight {
        rotate: 69deg;
        right: 2vw;
        bottom: 3rem;
        position: absolute;
        height: 15vw;
    }
}

.carouselContainer {
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    width: 80%;
    margin-top: 3vh;
}

/* TESTIMONIALS MOBILE VERSION */

.testimonialsTitleMobile {
    display: flex;
    width: 100vw;
    height: 20vh;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    .titleHeaderMobile {
        max-width: 80vw;
        font-size: var(--font-size-title-mobile);
        font-weight: bold;
        color: var(--color-background);
        text-align: center;
    }

    .highlightMobile {
        height: 2vh;
        width: 40vw;
    }
}

.bubbleLeftMobile {
    rotate: 1deg;
    left: 2vw;
    position: absolute;
    height: 34vw;
}

.bubbleRightMobile {
    rotate: 69deg;
    right: 2vw;
    position: absolute;
    height: 30vw;
}
.testimonialsDoodlesMobile {
    height: 10rem;

    .bubbleLeft {
        rotate: 1deg;
        left: 2vw;
        position: absolute;
        height: 9rem;
    }

    .bubbleRight {
        rotate: 69deg;
        right: 2vw;
        position: absolute;
        height: 9rem;
    }
}

.offerDialog p {
    text-indent: 0;
}

/* DEMO SECTION */
.demoSection {
    position: relative;

    /* h1{
        margin: 0;
    }
    h2{
        margin: 0;
    }
    p{
        margin: 0;
    } */
}

.demoHeader {
    margin: 0;
    /* text */
    font-weight: bold;
    font-size: var(--font-size-title);
    color: var(--color-font-secondary);
    /* positioning */
    width: 100%;
    position: relative;
    text-align: center;
    padding: 40px 0;
    background-image: url("../../assets/background_ce_invatam.png");
    background-size: cover;
}

.demoHeaderMobile {
    /* text */
    font-weight: bold;
    font-size: var(--font-size-title-mobile);
    color: var(--color-font-secondary);
    /* positioning */
    margin: 0;
    width: 100%;
    position: relative;
    text-align: center;
    padding: 40px 0;
    background-image: url("../../assets/background_ce_invatam.png");
    background-size: cover;
}

.demoHighlight {
    position: absolute;
    height: 2rem;
    width: 20rem;
    top: 6rem;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.demoHighlightMobile {
    position: absolute;
    height: 2vh;
    width: 40vw;
    top: 5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.demoMainContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-image: url("../../assets/background_ce_invatam.png");
    background-size: cover;
    border-radius: 0 0 10% 10%;
    padding-bottom: 20px;
}

.demoMainContainerMobile {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-image: url("../../assets/background_ce_invatam.png");
    background-size: cover;
    padding-bottom: 10px;
}

.demoFooter {
    /* text */
    font-weight: bold;
    font-size: var(--font-size-title);
    color: var(--color-font-secondary);
    /* positioning */
    width: 100%;
    position: relative;
    text-align: center;
    padding: 40px 0;

    .demoFooterHeader {
        font-size: var(--font-size-header);
        margin-bottom: 20px;
    }

    .demoFooterHeaderMobile {
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 80vw;
        font-size: var(--font-size-title-mobile);
        font-weight: bold;
        margin-bottom: 20px;
        
    }

    .demoFooterText {
        text-align: center;
        max-width: 80vw;
        font-size: var(--font-size-text-big);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-weight: normal;
    }
    
    .demoFooterTextMobile {
        text-align: center;
        max-width: 80vw;
        font-weight: bold;
        font-size: var(--font-size-text-big-mobile);
        left: 0;
        right: 0;
        margin: 0 auto;
        font-weight: normal;
    }
}

.demoMain {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    height: 25rem;
    flex-flow: row;
}

.demoCard {
    width: 25rem;
    padding: 10px;
    color: var(--color-secondary);
}

.demoCardHeader {
    text-align: center;
    font-size: var(--font-size-paragraph-small);
}

.demoCardText {
    text-align: center;
    margin-top: 1rem;
    font-size: var(--font-size-text-big);
}

.demoCardMobile {
    padding: 10px;
    color: var(--color-secondary);
}

.demoCardMobile h3 {
    text-align: center;
    font-size: var(--font-size-paragraph-small-mobile);
}
.demoCardMobile p {
    text-align: center;
    margin-top: 1rem;
    font-size: var(--font-size-text-big-mobile);
    padding-left: 10px;
    padding-right: 10px;
}

/*
 .demoMain {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    width: 100%;
    height: 20rem;
    flex-flow: row;
    h3 {
        margin: 0;
    }
    p {
        margin: 0;
    }

    .demoCard {
        width: 25rem;
        padding: 10px;
        color: var(--color-secondary);
        h3 {
            text-align: center;
            font-size: var(--font-size-paragraph-small);
        }
        p {
            text-align: center;
            margin-top: 1rem;
            font-size: var(--font-size-text-big);
        }
    }

    .demoCardMobile {
        padding: 10px;
        color: var(--color-secondary);
        h3 {
            text-align: center;
            font-size: var(--font-size-paragraph-small-mobile);
        }
        p {
            text-align: center;
            margin-top: 1rem;
            font-size: var(--font-size-text-big-mobile);
            padding-left: 10px;
            padding-right: 10px;
        }
    }
} 
*/

.demoRomb {
    margin-top: auto;
    margin-bottom: auto;
    height: 15rem;
}

.freeTrialBanner {
    color: white;
    display: flex;
    align-items: center;
    flex-wrap: center;
    flex-direction: row;
    margin-bottom: 10vh;
    padding: 0 4px;
}

.freeTrialBanner p {
    font-weight: 700;
    text-indent: 6px;
    font-size: var(--font-size-text-big-dynamic);
    text-align: center;
}
