.offerBanner {
    width: 100vw;
    height: calc(20vh + 15vw);
    display: block;
    position: relative;
    text-align: center;
    color: white;
}

.offerBanner img {
    filter: blur(2px);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#offerBannerFilter {
    background-color: rgb(45, 0, 87);
    content: "";
    width: 100vw;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    filter: opacity(0.5);
    display: block;
}

.underlinedTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
}

.underlinedTitle h2 {
    font-size: calc(10px + 2.5vw);
    text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    margin-bottom: 0;
    word-wrap: break-word;
}

.underlinedTitle img {
    width: 70%;
    filter: brightness(0) invert(1);
}