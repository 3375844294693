body {
    background-color: var(--color-background);
    color: var(--font-color-primary);

    /* hide scrollbar */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    display: none;
}

.big-button {
    font-size: 24px;
}

.small-button {
    font-size: 16px !important;
    padding: 10px 0px! important;
    border-radius: 12px !important;
    font-size: 16px;
}

.main-container {
    clear: both;
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - var(--header-height));
    /*this is to keep distance from the header*/
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.primary-button {
    background-color: var(--color-secondary);
    color: white;
    border-radius: 24px;
    box-shadow: 0;
    padding: 24px 80px;
    font-weight: var(--font-weight-md);
}
.primary-button:hover {
    background-color: var(--color-primary);
    transition: background-color 0.2s ease;
}

.secondary-button {
    background-color: var(--color-background);
    color: var(--color-secondary);
    border-radius: 24px;
    box-shadow: 0;
    padding: 24px 80px;
    font-weight: var(--font-weight-md);
}
.secondary-button:hover {
    background-color: var(--color-primary);
    transition: background-color 0.2s ease;
}

a:link { 
    color: inherit;
    text-decoration: none; 
} 
a:visited { 
    color: inherit;
    text-decoration: none; 
} 
a:hover { 
    color: inherit;
    text-decoration: none; 
} 
a:active { 
    color: inherit;
    text-decoration: none; 
}