footer {
    background-color: var(--color-secondary);
    padding: 40px 1vw 0;
    margin: 0;

    /* background-image: url("../../assets/background_ce_invatam.png"); */
    background-color: rgb(41, 3, 71);
}

.footerMainContainer {
    margin: auto;
}

.footerMainContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5vw;
    position: relative;
}

.footerColumn {
    max-width: 300px;
    width: 80vw;
}

.footerColumn img {
    object-fit: cover;
    width: 50%;
}

.footerColumn p {
    text-indent: 0;
    font-weight: normal;
    color: var(--color-font-secondary);
}

.footerColumn h2 {
    color: var(--color-font-secondary);
}

.contactIcon {
    transform: translateY(4px);
    margin: 0;
    padding: 0;
    padding-right: 4px;
}

.socialColumn {
    display: block;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 20px 0;
}

.socialMediaIcon {
    transform: scale(1.5);
    color: var(--color-font-secondary);
}
.socialMediaIcon:hover {
    transform: scale(1.5);
    color: var(--color-primary);
}

.footerSignature {
    padding: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
}
.footerSignature p {
    font-weight: normal;
    font-size: var(--font-size-small);
    padding: 0;
    margin: 0;
    text-indent: 0;
    color: var(--color-font-secondary);
}

.emojiReference {
    padding: 0px 4px;
}

@media only screen and (max-width: 1200px) {
    .footerMainContent {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footerSignature {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footerColumn img {
        width: 100%;
    }

    .socialColumn {
        align-items: center;
        justify-content: center;
    }
}
