.sidebar {
    /* base */
    width: 60%;
    height: 100%;
    background-color: var(--color-secondary);
    color: white;
    left: -250px;
    position: fixed;
    /* animation */
    transform: translateX(250px); 
    transition: transform 0.4s ease;

    z-index:999;
}

.sidebarClosing {
    /* base */
    width: 60%;
    height: 100%;
    background-color: var(--color-secondary);
    color: white;
    transition: transform 0.3s ease;
    left: -250px;
    position: fixed;
    /* animation */
    transform: translateX(-250px); 
    transition: transform 0.2s ease;

    z-index:999;
}

.overlay {
    /* base */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background-color: rgba(0, 0, 0, 0.3);
    /* animation */
    opacity: 1;
    transition: opacity 0.2s ease;

    z-index: 999;
  }

  .overlayClosing {
    /* base */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--header-height));
    background-color: rgba(0, 0, 0, 0.3);
    /* animation */
    opacity: 0;
    transition: opacity 0.2s ease;

    z-index: 999;
  }

  .buttonsContainer {
    display: flex;
    flex-flow: column;
  }
  
  .doodle {
    height: 100px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .highlight {
    width: 150px;
    margin-top: -15px;
    margin-left: 4vw;
  }

  .item {
    font-size: var(--font-size-header-mobile);
    margin-left: 3vw;
    padding: 24px 20px;
  }

  .sidebarButton {
    background-color: var(--color-background);
    color: var(--color-secondary) !important;
    border-radius: 24px;
    box-shadow: 0;
    margin-left: 3vw;
    padding: 24px 20px;
    font-weight: var(--font-weight-md);
    width: 150px;
    font-size: var(--font-size-paragraph-big-mobile);

    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
  }

  .sidebarButton:hover {
    background-color: var(--color-primary);
    transition: background-color 0.2s ease;
  }