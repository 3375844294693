.cardWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;

    margin-left: 10px;
    margin-right: 10px;

    border: 4px solid var(--color-tertiary);
    border-radius: 30px;
    height: 200px;
    width: 350px;

    color: var(--color-secondary);
}

.text {
    padding: 0 20px;
    font-size: var(--font-size-text-small);
}

.starsDoodle {
    transform: scale(0.5);
}

.user {
    font-size: var(--font-size-text-small);
    justify-content: center;
    display: flex;
    margin: 0;
}

.lineDoodle {
    position: relative;
    transform: scale(0.2);

}

h6 {
    margin: 0;
    font-size: 1rem;
    width: 100%;
}