/* https://codepen.io/trevoreyre/pen/eegqXP */
.reviewPageContent {
    padding: 3rem 0.5rem;
    max-width: 1800px;
    margin: auto;
    columns: 3 400px;
    column-gap: 1rem;
    min-height: 40vh;
}

.reviewPageContent h3 {
    color: var(--color-secondary);
    text-align: center;
}

.longReviewCard {
    padding: 0.5vh 1vw;
    position: relative;
    margin-bottom: 24px;
    overflow: hidden;
    break-inside: avoid;
    border-radius: 8px;
    border: 3px solid var(--color-secondary);
}
