.stickyOverlayContainer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 4px;
    z-index: 50000;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
}

.directContactContainer {
    display: flex;
    justify-content: flex-end;
    align-items: row;
    color: white;
    gap: 4px;
    padding-right: 4px;
}

.directContactContainer button {
    pointer-events: auto;
    border-radius: 100%;
    width: calc(50px + 0.5vw);
    height: calc(50px + 0.5vw);
    z-index: 50000;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsappButton {
    background-color: rgb(0, 138, 0);
}
.whatsappButton:hover {
    background-color: rgb(0, 216, 0);
}
.whatsappButton svg {
    width: calc(40px + 0.3vw);
    height: calc(40px + 0.3vw);
}

.messengerButton {
    background-color: #4505bb;
}
.messengerButton:hover {
    background-color: #701ef5;
}
.messengerButton svg {
    width: calc(40px + 0.3vw);
    height: calc(40px + 0.3vw);
    transform: scale(0.8);
}

.facebookButton {
    background-color: #0554bb;
}
.facebookButton:hover {
    background-color: #0c6bfa;
}
.facebookButton svg {
    width: calc(40px + 0.3vw);
    height: calc(40px + 0.3vw);
}

.cookieConsentBanner {
    padding: 12px 0 0;
    min-height: 80px;
    width: 100%;
    pointer-events: auto;
    background-color: rgb(21, 16, 31);
}

.cookieConsentContent {
    max-width: 700px;
    padding: 0px 4px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: var(--font-size-text-big-mobile);
}

.cookieConsentContent div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.cookieConsentContent button {
    min-width: 250px;
}